import { EventAggregator }                  from 'aurelia-event-aggregator';
import { inject }                           from 'aurelia-framework';
import { I18N }                             from 'aurelia-i18n';
import { ListingRevisionsRepository }       from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { SpecificationRevisionsRepository } from 'modules/management/specifications/specifications/specification-revisions/services/repository';
import { BaseFilterFormSchema }             from 'resources/classes/base-filter-form-schema';
import { SessionStorage }                   from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ListingRevisionsRepository, SpecificationRevisionsRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:          [],
        created_at_from:     null,
        created_at_to:       null,
        revisions_in_effect: 1,
    };

    constructor(storage, eventAggregator, i18n, listingRevisionsRepository, specificationRevisionsRepository) {
        super(storage, eventAggregator, i18n);

        this.listingRevisionsRepository       = listingRevisionsRepository;
        this.specificationRevisionsRepository = specificationRevisionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.test_code = {
            type:     'text',
            key:      'test_code',
            label:    'form.field.test-code',
            size:     4,
            required: false,
        };

        this.name = {
            type:     'text',
            key:      'name',
            label:    'form.field.name',
            size:     4,
            required: false,
        };

        this.revision_number = {
            type:     'text',
            key:      'revision_number',
            label:    'form.field.revision-number',
            size:     4,
            required: false,
        };

        this.norm = {
            type:     'text',
            key:      'norm',
            label:    'form.field.norm',
            size:     4,
            required: false,
        };

        this.norm_edition = {
            type:     'text',
            key:      'norm_edition',
            label:    'form.field.norm-edition',
            size:     4,
            required: false,
        };

        this.bulletin_model = {
            type:     'text',
            key:      'bulletin_model',
            label:    'form.field.bulletin-model',
            size:     4,
            required: false,
        };

        this.specification_revisions_ids = {
            type:         'multiselect-native',
            key:          'specification_revisions_ids',
            label:        'form.field.specification-books',
            size:         4,
            observers:    [
                (newValue) => {
                    const selectedIds = this.specification_revisions_ids.instance.selectedItems().map(item => item.id);

                    let target = this.listings_ids;

                    if (selectedIds && selectedIds.length > 0) {
                        target.remoteSource = () => this.listingRevisionsRepository.searchListings({ specification_revisions_ids: selectedIds });
                    } else {
                        target.remoteSource = () => Promise.resolve([]);
                    }
                    target.instance.fetchData();
                },
            ],
            remoteSource: () => this.specificationRevisionsRepository.all(),
            required:     false,
        };

        this.listings_ids = {
            type:         'multiselect-native',
            key:          'listings_ids',
            label:        'form.field.listings',
            size:         8,
            remoteSource: () => viewModel.filterModel.specification_revisions_ids
                ? this.listingRevisionsRepository.searchListings({ specification_revisions_ids: viewModel.filterModel.specification_revisions_ids })
                : Promise.resolve([]),
            required:     false,
        };

        this.revisions_in_effect = {
            type:     'checkbox',
            key:      'revisions_in_effect',
            label:    'form.field.revisions-in-effect',
            size:     4,
            required: false,
        };

        this.created_by = {
            type:     'users-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     4,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                bulletinId: viewModel.bulletinId,
                criteria:   viewModel.filterModel,
            }),
            attributes: {
                class: 'btn',
                style: 'background-color: #0092D5 !important; border-color: #0092D5 !important; color: #ffffff !important;',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.test_code, this.name, this.revision_number],
            [this.norm, this.norm_edition, this.bulletin_model],
            [this.specification_revisions_ids, this.listings_ids],
            [this.revisions_in_effect],
            [this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
